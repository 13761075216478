import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  DeleteMessage,
  LoadMessage,
  LoadMessageTemplates,
  MessagesState,
  UpdateMessage,
} from '@vandelft/modules/shared/state/messages';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { Message, MessageTemplate } from '@vandelft/modules/shared/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Navigate } from '@ngxs/router-plugin';
import { EnvironmentState } from '@vandelft/modules/shared/state/environment';

@Component({
  selector: 'app-edit-message',
  styleUrls: ['./edit-message.component.scss'],
  templateUrl: './edit-message.component.html',
})
export class EditMessageComponent implements OnInit, OnDestroy {
  @Select(MessagesState.message) public message$: Observable<Message>;
  @Select(MessagesState.templates) public templates$: Observable<MessageTemplate[]>;
  public prefix$: Observable<string> = inject(Store).select(EnvironmentState.prefix);

  private subscriptions: Subscription[] = [];

  public constructor(
    private store: Store,
    private router: ActivatedRoute,
  ) {}

  public form: FormGroup = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    message: new FormControl(null),
    template: new FormControl(null),
    scheduled: new FormControl(null),
  });

  public ngOnInit(): void {
    this.store.dispatch(new LoadMessageTemplates());
    this.subscriptions.push(
      this.router.params.subscribe((params: Params): void => {
        this.store.dispatch(new LoadMessage(params.id));
      }),
      this.message$.subscribe((message: Message): void => {
        if (message === null) {
          return;
        }
        this.form.patchValue({
          ...message,
          scheduled:
            message.scheduled === null
              ? null
              : moment.tz(message.scheduled, moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'),
        });
      }),
    );
  }

  public getTemplate(template: string, templates: MessageTemplate[]): MessageTemplate {
    return templates.find((t: MessageTemplate): boolean => t.name === template);
  }

  public submit(): void {
    const message = { ...this.form.value };
    if (!message.id) {
      return;
    }
    if ((message.scheduled as any) === '') {
      message.scheduled = null;
    }
    if (message.scheduled !== null) {
      message.scheduled = moment.tz(message.scheduled, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm');
    }
    this.store.dispatch(new UpdateMessage(message));

    const prefix: string = this.store.selectSnapshot(EnvironmentState.prefix);
    this.store.dispatch(new Navigate([`/${prefix}/messages`]));
  }

  public delete(id: string): void {
    if (!confirm('Weet je zeker dat je dit bericht wilt verwijderen?')) {
      return;
    }
    this.store.dispatch(new DeleteMessage(id));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }
}
